import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import PaymentPlanCard from './plan-card';

const PaymentPlan = () => {
  const plans = [
    {
      id: 'month',
      name: 'Monthly Plan',
      price: '9.99',
      color: 'gray',
      benefits: [
        'Create Transaction Sheets',
        'Get Account Details',
        'Get Account Summary',
        'Comparison of spendings',
        'Detailed Charts',
      ],
      link: `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/payment`,
    }
    // ,
    // {
    //   id: 'year',
    //   name: 'Yearly Plan',
    //   price: '49.99',
    //   color: 'blue',
    //   benefits: [
    //     'Create Transaction Sheets',
    //     'Get Account Details',
    //     'Get Account Summary',
    //     'Comparison of spendings',
    //     'Detailed Charts',
    //   ],
    //   link: `${process.env.REACT_APP_API_SERVER_ENDPOINT}/api/payment`,
    // },
  ];

  return (
    <Box w="100%" h="100%" mb="10">
      <Text
        display="none"
        mt="5"
        fontSize={['26px', '26px', '28px', '30px', '32px']}
        fontWeight="700"
        align="center"
      >
        Choose Your Payment Plan
      </Text>

      <Flex
        mt="10"
        gap="5"
        justify="space-around"
        direction={['column', 'column', 'row', 'row', 'row']}
      >
        <PaymentPlanCard data={plans[0]} />
        {/* <PaymentPlanCard data={plans[1]} /> */}
      </Flex>
    </Box>
  );
};

export default PaymentPlan;
